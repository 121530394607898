import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import LandingTemplates from '../data/templates';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const LANDING_DEFAULT = {
  path: '/l/:id',
  name: 'landing',
  component: () => import('../views/Landing.vue'),
};

const createLandingPageRoutes = () => {
  for (let i = 0; i < LandingTemplates.length; i++) {
    const l = LandingTemplates[i];
    routes.push({
      ...LANDING_DEFAULT,
      name: `landing-${l.name}`,
      path: `/l/${l.name}`,
      props: { content: l },
    });
  }
}

createLandingPageRoutes();

const router = new VueRouter({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  base: process.env.BASE_URL,
  routes,
});

export default router;
