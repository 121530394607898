class Template extends Object {
  constructor(
    public name: string,
    public heading: string,
    public subheading: string,
    public block_heading: string,
    public block_content: string[],
    public example: boolean) {
    super()
  }
}

const fitness = new Template(
  'fitness',
  'Offer paid memberships on your fitness workouts',
  'Monetize your content and make monthly revenue with easy-to-use pages.',
  'You\'ve got the content, but how do you turn it into a business?',
  [
    'It\'s hard to make a living with sponsorships and ads. That\'s where Fanfolk comes in. If you have a dedicated group of followers, you can create a paid membership service with your existing content.',
    'You can even use the content you have today. Just copy and paste. Or, create in our content builder.'
  ],
  true
);

const cooking = new Template(
  'cooking',
  'Turn your recipes into recurring revenue.',
  'Add your content, share with your fans, and make recurring revenue.',
  'You\'ve got the content, but how do you turn it into a business?',
  [
    'If you have a dedicated group of followers, you can create a paid membership service with your existing content.',
    'You can even use the content you have today. Just copy and paste. Or, create in our content builder.'
  ],
  true
);

const blog = new Template(
  'blog',
  'Turn your blog into a paid membership site.',
  'Add your content, share with your fans, and make recurring revenue.',
  'You\'ve got the content, but how do you turn it into a business?',
  [
    'If you have a dedicated group of followers, you can create a paid membership service with your existing content.',
    'You can even use the content you have today. Just copy and paste. Or, create in our content builder.'
  ],
  true
);

export default [
  fitness,
  cooking,
  blog
]
