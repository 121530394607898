<template>
  <div class="home" ref="home" :class="status">
    <div class="home-main">
      <div class="home-main__content">
        <h1>Monetize your content <span class="heading-aside">with subscriptions from your fans</span></h1>
      </div>
      <div class="home-main__img" ref="img"></div>
    </div>
    <div class="home-sub">
      <div class="home-sub__left">
        <p>As a digital creator, it's hard to monetize your content without advertisements. We offer another way.</p>
        <p>With Fanfolk, you're a couple clicks away from your own subscription platform. Add your content, set your price, and tell your fans.</p>
        <p>Get paid instantly, then on a recurring basis as your fans subscribe. It's like magic.</p>
        <p>
          Digital Magazines <br>
          Art <br>
          Culture <br>
          Education <br>
          Knowledge <br>
          Recipes <br>
          Fitness <br>
          Influencer Platforms <br>
          Podcast Communities <br>
          You <br>
        </p>
      </div>
      <div class="home-sub__right">
        <p>Fanfolk currently has a waitlist.</p>
        <button class="ui-button" type="button" name="button" @click="navigate(`https://tom702312.typeform.com/to/GHZ4p8t2`)">
          Get Early Access
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HoverEffect from 'hover-effect';

export default {
  name: 'Home',
  props: [
    'navigate',
    'status',
    'bus'
  ],
  data() {
    return {
      animated: false
    }
  },
  methods: {
  },
  created() {
    analytics.track('Page Viewed', { name: 'Home' });
  },
  mounted() {
    const ani = new HoverEffect({
      parent: this.$refs.img,
      intensity: 1,
      speedIn: 2,
      speedOut: 3,
      easing: 'Quad.easeOut',
      image1: require('@/assets/holo2.jpg'),
      image2: require('@/assets/hologram3.jpg'),
      displacementImage: require('@/assets/hologram2.jpg'),
    });
  },
};
</script>

<style scoped lang="scss">
  @import "../assets/styles/app.scss";

  .home {
    @include container;

    font-family: $fontFamily;
    font-style: normal;
    padding-bottom: 8rem;

    &.is-bottom {
      @include status;
    }
  }

  .home-main {
    position: relative;
    transition: 1s ease-in all;

    &:hover {
      @include status;
    }
  }

  .home-main__content {
    position: relative;
    z-index: 1;
    transition: 1s ease all;
    max-width: 42rem;
  }

  .home-main__img {
    @include main-img;
  }

  h1 {
    font-family: $fontFamily-heading;
    font-size: $fontSize-large;
    font-weight: 300;
    text-align: left;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    transition: 0.65s ease all;
    max-width: 8ch;
  }

  .heading-aside {
    @include copy;
    display: block;
    margin-bottom: 5rem;
    margin-top: 5rem;
    position: relative;
    transition: 0.65s ease all;
    text-transform: uppercase;
    letter-spacing: 0.0275em;
    font-weight: 600;
    font-size: 1rem;


    &::before {
      content: "";
      position: absolute;
      top: -3ch;
      left: -2ch;
      background-image: url('../assets/circle.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: cover;
      display: block;
      height: 10ch;
      width: 33ch;
    }
  }

  p {
    @include copy;
  }

  .home-sub {
    position: relative;
  }

  @media screen and (max-width: 800px) {
    .home {
      padding: 1.5rem 1.5rem 8rem;
    }
    h1 {
      background-size: 5rem;
    }
    h2 {
      font-size: 4rem;
      margin: 0 0 2rem 0;
    }
    .home-main__img {
      position: absolute;
      height: 12rem;
      width: 100%;
      left: 0;
      top: 7rem;
    }
  }

</style>
