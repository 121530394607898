<template>
  <div class="app" id="app">
    <nav class="app-nav" id="nav">
      <div class="app-nav__left">
        <router-link class="logo" to="/">Home</router-link>
      </div>
      <div class="app-nav__right">
        <router-link class="interior" to="about">About</router-link>
        <button class="link is-exterior" @click="navigate(`https://blog.fanfolk.com`)">Product / Blog</button>
        <button class="link is-primary" @click="navigate(`https://tom702312.typeform.com/to/GHZ4p8t2`)">Get Early Access</button>
      </div>
    </nav>
    <transition name="view" class="app-transition" appear>
      <router-view :navigate="navigate" :status="status"/>
    </transition>
    <footer class="footer">
      <section class="footer-section">
        <h1 class="footer-section__heading">More Information</h1>
        <ul class="footer-section__list">
          <li class="footer-section__list-item" v-for="(link, index) in socialLinks" :key="index">
            <a v-if="link.external" target="_blank" :href="link.location">{{link.text}}</a>
            <router-link v-if="!link.external" :to="link.location">{{link.text}}</router-link>
          </li>
        </ul>
      </section>
      <section class="footer-section is-hidden">
        <h1 class="footer-section__heading">Use Cases</h1>
        <ul>
          <li v-for="(link, index) in links" :key="index">
            <router-link :to="{ name: `landing-${link.name}` }">{{link.heading}}</router-link>
          </li>
        </ul>
      </section>
    </footer>
  </div>
</template>
<script>
import LandingTemplates from './data/templates'

export default {
  name: 'App',
  data() {
    return {
      status: 'is-top',
      links: LandingTemplates,
      socialLinks: [
        {
          text: 'About',
          location: 'about',
        },
        {
          text: 'Blog',
          location: 'https://blog.fanfolk.com',
          external: true
        },
        {
          text: 'Instagram',
          location: 'https://www.instagram.com/fanfolkdotcom/',
          external: true
        },
      ]
    }
  },
  methods: {
    navigate(location) {
      analytics.track('Navigate', { location: location });
      window.location.href = location
    },
  },
};
</script>
<style lang="scss">
@import "./assets/styles/app.scss";
  html, body {
    background: black;
    color: white;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    font-size: 16px;
    margin: 0;
    overflow-x: hidden;
  }

  .app-transition {
    transition: 1s ease all;
  }

  .app-nav {
    display: flex;
    padding: $fontSize-normal;
    justify-content: space-between;
    align-items: center;

    a,
    .link {
      @include link;
    }

    .logo {
      font-size: 2rem;
      background-image: url('./assets/logo.svg');
      background-repeat: no-repeat;
      background-size: 6.5rem;
      background-position: 6px 0;
      color: transparent;
      height: 2.5rem;
      width: 8rem;
      transition: 0.65s ease all;
      display: inline-block;
    }
  }

  .ui-button {
    @include link('primary');
  }

  .footer-section {
    @include container;
    padding-bottom: 10rem;

    &.is-hidden {
      display: none;
    }
  }

  .footer-section__heading {
    @include copy;
    margin-bottom: 1rem;
  }

  .footer-section__list {
    padding: 0;
    margin: 0;
  }

  .footer-section__list-item {
    list-style: none;
    padding: 0;
    margin: 0 0 .5rem 0;

    a {
      @include copy;
      color: $color-white;
      font-size: $fontSize-small;
    }
  }

  @media screen and (max-width: 800px) {
    .app-nav {
      display: block;

      .logo {
        height: 1.5rem;
      }
    }
    .footer-section {
      padding: 0 2rem 10rem;
    }
  }

</style>
